import React, { Component } from 'react';
import { BsFillCheckCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import './styles.css'

export default class NotificationToaster extends Component {
  constructor() {
    super();
    this.state = {
      ready: false
    };
  }
  pushNotice = (notificationArray = []) => {
    let autoClose = 1700;
    notificationArray?.length > 0 &&
      notificationArray?.map(item => {
        if (item.type == 'danger' || item?.type === undefined) {
          toast.error(
            <CustomToast
              icons={<BsFillExclamationCircleFill color={"#e74c3c"} />}
              titles={'Error'}
              message={item.message}
            />,
            {
              autoClose: autoClose,
            });


        } else if (item.type == 'deleted' || item.type === undefined) {
          toast.error(
            <CustomToast
              icons={<BsFillExclamationCircleFill color={"#e74c3c"} />}
              titles={'Deleted'}
              message={item.message}
            />,
            {
              autoClose: autoClose,

            });


        } else if (item.type == 'invalid' || item.type === undefined) {
          toast.error(
            <CustomToast
              icons={<BsFillExclamationCircleFill color={"#e74c3c"} />}
              titles={''}
              message={item.message}
            />,
            {
              autoClose: autoClose,

            });


        } else if (item.type == 'error' || item.type === undefined) {
          toast.error(
            <CustomToast
              icons={<BsFillExclamationCircleFill color={"#e74c3c"} />}
              titles={'Error'}
              message={item.message}
            />,
            {
              autoClose: autoClose,


            });
        } else if (item.type == 'warning' || item.type === undefined) {
          toast.warning(
            <CustomToast
              icons={<BsFillExclamationCircleFill color={"#f1c40f"} />}
              titles={'Info'}
              message={item.message}
            />
            , {
              autoClose: autoClose,


            });
        } else if (item.type == 'info' || item.type === undefined) {
          toast.info(
            <CustomToast
              icons={<BsFillExclamationCircleFill color={"#3498db"} />}
              titles={'Info'}
              message={item.message}
            />, {
            autoClose: autoClose,

          });

        } else {
          toast.success(

            <CustomToast
              icons={<BsFillCheckCircleFill color={"#12AF23"} />}
              titles={'Success'}
              message={item.message}
            />
            , {
              autoClose: autoClose,


            });
        }
      });
  };
  render() {
    if (!this.state.ready) {
      window.notify = this.pushNotice;
    }
    return null;
  }
}

const CustomToast = (
  {
    icons,
    titles,
    message
  }
) => {
  return (
    <div className='d-flex align-items-center w-100 custom-toast'>
      {icons}
      <section>
        <p style={{ fontWeight: "bold", color: "#000" }}>{titles}</p>{" "}
        <span>{message}</span>
      </section>
    </div>
  )
}
