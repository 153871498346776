import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import MessageConstants, {
    getMessageName
} from "../../../blocks/studio-store-ecommerce-components/src/MessageConstants";
import { runEngine } from "../../../framework/src/RunEngine";
import { uuid } from "uuidv4";
import { RouteComponentProps } from "react-router-dom";
// @ts-ignore
import TawkTo from "tawkto-react";

const configJSON: any = require('./config');
// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    title: string;
    favicon: string;
    isAuthenticated: boolean
    invalidTokenMessageRecieved: boolean
    isTawkToPresent: boolean
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


class AppController extends BlockComponent<Props, S, SS> {
    generateGuestTokenAPICallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getMessageName(MessageConstants.updateFaviconAndTitle),
            getMessageName(MessageConstants.reGenerateGuestToken),
            // Customizable Area End
        ];
        // Customizable Area Start
        const {favicon, title} = JSON.parse(localStorage.getItem("favicon-title") ?? "{}");
        this.state = {
            title,
            favicon,
            isAuthenticated: false,
            invalidTokenMessageRecieved: false,
            isTawkToPresent: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    logoutAndRedirectToLoginPage = () => {
        this.generateGuestToken();
        this.props?.history?.push({ pathname: '/login', state: { activeTab: "2", calledFrom: "guest-login" } });
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.initialize();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
                if (responseJson && !responseJson.errors && responseJson.data) {
                    // Customizable Area Start
                    if (apiRequestCallId === this.generateGuestTokenAPICallId) {
                        if (responseJson && responseJson.data && responseJson.meta) {
                            localStorage.setItem(
                                "guestUserData",
                                JSON.stringify(responseJson.data)
                            );
                            localStorage.setItem(
                                "guestUUID",
                                responseJson.data.attributes.uuid
                            );
                            localStorage.setItem("token", responseJson.meta.token);
                            this.setState({
                                isAuthenticated: true,
                                invalidTokenMessageRecieved: false
                            })
                        } else if (responseJson?.errors) {
                            //@ts-ignore
                            window.notify([
                                {
                                    type: "danger",
                                    message:
                                        "Technical error!",
                                },
                            ]);
                        }
                    }
                    // Customizable Area End
                }

            } else {
                this.setState({
                    invalidTokenMessageRecieved: true
                }, () => {
                    if (
                        Array.isArray(responseJson?.errors) &&
                        (responseJson?.errors.length > 0) &&
                        responseJson?.errors[0]?.token &&
                        (
                            responseJson?.errors[0]?.token == "Token has Expired" ||
                            responseJson?.errors[0]?.token == "Invalid token"
                        )
                    ) {
                        let user = localStorage.getItem('user');
                        let token = localStorage.getItem('token');
                        let guestUUID = localStorage.getItem('guestUUID');
                        if (!user && (token && guestUUID)) {
                            this.generateGuestToken()
                        } else if (user) {
                            localStorage.clear();
                            this.logoutAndRedirectToLoginPage()
                        }
                    }
                })
            }
            // Customizable Area End
        } else if (getMessageName(MessageConstants.reGenerateGuestToken) === message.id) {
            this.generateGuestToken()
        } else if (getMessageName(MessageConstants.updateFaviconAndTitle) === message.id) {
            const messageData = message.getData(
                getMessageName(MessageConstants.updateFaviconAndTitleData)
            );
            this.setState({
                title: messageData?.title,
                favicon: messageData.favicon
            })
        }
    }

    initialize = () => {
        let token = localStorage.getItem("token");

        if (!token) {
            this.generateGuestToken()
        } else {
            this.setState({
                isAuthenticated: true
            })
        }

        window.addEventListener("themeChange", (e: any) => {
            const appThemeDataObj = JSON.parse(localStorage.getItem("appThemData") ?? "{}");
            const tawkToKeys = appThemeDataObj?.["IntegrationKeys"]["tawk_keys"] ?? {}; 

            if (tawkToKeys?.["is_active"] && tawkToKeys?.["property_id"] && tawkToKeys?.["widget_id"]) {
                this.setState({ isTawkToPresent: true })
                var tawk = new TawkTo(tawkToKeys?.["property_id"], tawkToKeys?.["widget_id"])
                tawk.hideWidget()
            }
        });
    }

    generateGuestToken = () => {
        this.setState({ isAuthenticated: false }, () => {
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.generateGuestTokenAPICallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.guestLoginAPIEndPoint
            );
            const headers = {
                "Content-Type": "application/json",
            };
            const generateID = uuid();
            const requestBody = {
                data: {
                    type: "guest_account",
                    attributes: {
                        uuid: generateID,
                    },
                },
            };

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                headers
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(requestBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.guestLoginAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        })
    }



    // Customizable Area End
}

export default AppController